import React from 'react'
import PropTypes from 'prop-types'
import { link, graphql } from 'gatsby'
import Layout from '../components/Layout'

import GreenSideBar from '../components/GreenSideBar'

import Content, { HTMLContent } from '../components/Content'

export const CaseStudyTemplate = ({ title, content, image, sidebartext, contentComponent }) => {
const PageContent = contentComponent || Content
return (
  <div>
    <div
      className="full-width-image margin-top-0"
      style={{
        backgroundImage: `url(${
          !!image.childImageSharp ? image.childImageSharp.fluid.src : image
        })`,
        backgroundPosition: `top center`,
        // backgroundAttachment: `fixed`,
      }}
    ></div>
    <section className="section section--gradient">
      <div className="container">
        <div className="columns">
          <div className="column is-4 is-offset-1">
            <GreenSideBar content={sidebartext}></GreenSideBar>
          </div>
          <div className="column">
            <div className="section">
              <h2 className="title is-size-3 has-text-weight-bold is-bold-light"
                style={{texttransform: `uppercase`}}
              >
                {title}
              </h2>
              
              <PageContent className="content" content={content} />
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
  )
}
  
CaseStudyTemplate.propTypes = {
    image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    title: PropTypes.string,
    content: PropTypes.node.isRequired,
    contentComponent: PropTypes.func,
    sidebartext: PropTypes.string
}

const CaseStudy = ({ data }) => {
    const post = data
    return (
        <Layout>
            <CaseStudyTemplate
                image={post.markdownRemark.frontmatter.image}
                title={post.markdownRemark.frontmatter.title}
                content={post.markdownRemark.html}
                sidebartext={post.markdownRemark.frontmatter.sidebartext}
            />
        </Layout>
    )
 }

CaseStudy.propTypes = {
    data: PropTypes.shape({
      markdownRemark: PropTypes.object,
    }),
  }


 export default CaseStudy

 export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
        html
      frontmatter {
        title
        sidebartext
        image {
          childImageSharp {
            fluid(maxWidth: 2048, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }`